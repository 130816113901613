@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap");
/*COntents' font*/
@import url("https://fonts.googleapis.com/css2?family=Sanchez&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

/* ----- HEADER STYLE ----- */
#navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 30px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  transition: top 0.6s; }
  @media screen and (min-width: 768px) {
    #navBar {
      padding: 40px 50px; } }
  #navBar a {
    color: #fff; }
  #navBar .name {
    font-family: "Sanchez", serif;
    font-size: 1rem;
    position: relative;
    z-index: 999;
    text-transform: uppercase;
    letter-spacing: 5px;
    transition: 0.3s; }
    @media screen and (min-width: 992px) {
      #navBar .name {
        font-size: 1.4rem; } }
    #navBar .name:hover {
      opacity: 0.6; }

#navBar.animate {
  top: -130px; }

#navBar.sticky {
  top: 0; }

.menuDisplay {
  display: flex;
  position: relative;
  align-items: center; }
  .menuDisplay a {
    transition: 0.3s; }
    .menuDisplay a:hover {
      opacity: 0.6; }

.langDisplay {
  display: none; }
  @media screen and (min-width: 768px) {
    .langDisplay {
      display: inline-block; } }

.langBtn {
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  border-radius: 2px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 15px;
  transition: 0.3s;
  padding: 4px;
  font-size: 15px;
  width: 40px;
  height: 30px;
  line-height: 0; }
  .langBtn:hover {
    opacity: 0.6; }
  @media screen and (min-width: 768px) {
    .langBtn {
      margin-right: 50px; }
      .langBtn:first-child {
        margin-right: 25px; } }
  @media screen and (min-width: 992px) {
    .langBtn {
      margin-right: 0; }
      .langBtn:first-child {
        margin-right: 30px; } }

.proLinks {
  font-family: "Sanchez", serif;
  font-size: 1.85rem;
  margin-right: 75px;
  margin-left: 70px;
  letter-spacing: 2px;
  font-weight: 300;
  display: none; }
  .proLinks a:first-child {
    margin-right: 15px; }
  .proLinks a:last-child {
    margin-left: 15px; }
  @media screen and (min-width: 992px) {
    .proLinks {
      display: inline-block; } }

/* ----- HAMBURGER STYLE ----- */
.hamburgerIcon {
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out; }
  @media screen and (min-width: 768px) {
    .hamburgerIcon {
      width: 45px;
      height: 40px; } }
  @media screen and (min-width: 992px) {
    .hamburgerIcon {
      width: 50px;
      height: 50px; } }

.icon__line {
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
  height: 3px; }
  @media screen and (min-width: 768px) {
    .icon__line {
      border-radius: 4px;
      height: 4px; } }
  @media screen and (min-width: 992px) {
    .icon__line {
      border-radius: 5px;
      height: 5px; } }

.icon__line.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right; }

.icon__line.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left; }

.icon__line.half {
  width: 50%; }

.toggle.hamburgerIcon {
  transform: rotate(-45deg); }

.toggle .icon__line.start {
  transform: rotate(-90deg) translate3d(6.5px, 0, 0); }
  @media screen and (min-width: 768px) {
    .toggle .icon__line.start {
      transform: rotate(-90deg) translate3d(5px, 0, 0); } }
  @media screen and (min-width: 992px) {
    .toggle .icon__line.start {
      transform: rotate(-90deg) translate3d(3.5px, 0, 0); } }

.toggle .icon__line.end {
  transform: rotate(-90deg) translate3d(-6.5px, 0, 0); }
  @media screen and (min-width: 768px) {
    .toggle .icon__line.end {
      transform: rotate(-90deg) translate3d(-5px, 0, 0); } }
  @media screen and (min-width: 992px) {
    .toggle .icon__line.end {
      transform: rotate(-90deg) translate3d(-3.5px, 0, 0); } }

/* ----- NAVBAR STYLE ----- */
.navMenu {
  position: fixed;
  background-color: #007d91;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -99; }
  .navMenu a {
    display: inline-block;
    letter-spacing: 5px;
    text-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
    position: relative;
    transform: translate3d(0, 100%, 0); }

.navMenu_active {
  transform: translate3d(0, 0, 0); }

.navMenu__list {
  text-align: center; }
  .navMenu__list li {
    margin-top: 35px;
    font-family: "Sanchez", serif;
    text-transform: uppercase;
    font-size: 3rem;
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .navMenu__list li {
        font-size: 4rem; } }
    @media screen and (min-width: 992px) {
      .navMenu__list li {
        font-size: 5rem; } }
    .navMenu__list li:first-child {
      margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .navMenu__list li:last-child {
        font-size: 3rem; } }
    .navMenu__list li a::after {
      content: "";
      width: 0px;
      height: 4px;
      position: absolute;
      background-color: #fff;
      top: 90%;
      left: 0;
      box-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
      transition: 0.4s; }
    .navMenu__list li a:hover::after {
      width: 100%; }

.responsive__proLinks {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (min-width: 992px) {
    .responsive__proLinks {
      display: none; } }

.responsive__langDisplay {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (min-width: 768px) {
    .responsive__langDisplay {
      display: none; } }

.responsive__langBtn {
  border: 3px solid #fff;
  color: #fff;
  background-color: transparent;
  padding: 2px;
  border-radius: 3px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 20px;
  width: 45px;
  height: 40px;
  transition: 0.3s;
  transform: translate3d(0, 100%, 0);
  box-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
  margin-right: 5px; }

@keyframes navMenuLinksSlide {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }
