@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
/*COntents' font*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

/* ----- HEADER STYLE ----- */
#navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 30px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  transition: top 0.6s; }
  @media screen and (min-width: 768px) {
    #navBar {
      padding: 40px 50px; } }
  #navBar a {
    color: #fff; }
  #navBar .name {
    font-family: "Sanchez", serif;
    font-size: 1rem;
    position: relative;
    z-index: 999;
    text-transform: uppercase;
    letter-spacing: 5px;
    transition: 0.3s; }
    @media screen and (min-width: 992px) {
      #navBar .name {
        font-size: 1.4rem; } }
    #navBar .name:hover {
      opacity: 0.6; }

#navBar.animate {
  top: -130px; }

#navBar.sticky {
  top: 0; }

.menuDisplay {
  display: flex;
  position: relative;
  align-items: center; }
  .menuDisplay a {
    transition: 0.3s; }
    .menuDisplay a:hover {
      opacity: 0.6; }

.langDisplay {
  display: none; }
  @media screen and (min-width: 768px) {
    .langDisplay {
      display: inline-block; } }

.langBtn {
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  border-radius: 2px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 15px;
  transition: 0.3s;
  padding: 4px;
  font-size: 15px;
  width: 40px;
  height: 30px;
  line-height: 0; }
  .langBtn:hover {
    opacity: 0.6; }
  @media screen and (min-width: 768px) {
    .langBtn {
      margin-right: 50px; }
      .langBtn:first-child {
        margin-right: 25px; } }
  @media screen and (min-width: 992px) {
    .langBtn {
      margin-right: 0; }
      .langBtn:first-child {
        margin-right: 30px; } }

.proLinks {
  font-family: "Sanchez", serif;
  font-size: 1.85rem;
  margin-right: 75px;
  margin-left: 70px;
  letter-spacing: 2px;
  font-weight: 300;
  display: none; }
  .proLinks a:first-child {
    margin-right: 15px; }
  .proLinks a:last-child {
    margin-left: 15px; }
  @media screen and (min-width: 992px) {
    .proLinks {
      display: inline-block; } }

/* ----- HAMBURGER STYLE ----- */
.hamburgerIcon {
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out; }
  @media screen and (min-width: 768px) {
    .hamburgerIcon {
      width: 45px;
      height: 40px; } }
  @media screen and (min-width: 992px) {
    .hamburgerIcon {
      width: 50px;
      height: 50px; } }

.icon__line {
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
  height: 3px; }
  @media screen and (min-width: 768px) {
    .icon__line {
      border-radius: 4px;
      height: 4px; } }
  @media screen and (min-width: 992px) {
    .icon__line {
      border-radius: 5px;
      height: 5px; } }

.icon__line.start {
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: right; }

.icon__line.end {
  align-self: flex-end;
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform-origin: left; }

.icon__line.half {
  width: 50%; }

.toggle.hamburgerIcon {
  transform: rotate(-45deg); }

.toggle .icon__line.start {
  transform: rotate(-90deg) translate3d(6.5px, 0, 0); }
  @media screen and (min-width: 768px) {
    .toggle .icon__line.start {
      transform: rotate(-90deg) translate3d(5px, 0, 0); } }
  @media screen and (min-width: 992px) {
    .toggle .icon__line.start {
      transform: rotate(-90deg) translate3d(3.5px, 0, 0); } }

.toggle .icon__line.end {
  transform: rotate(-90deg) translate3d(-6.5px, 0, 0); }
  @media screen and (min-width: 768px) {
    .toggle .icon__line.end {
      transform: rotate(-90deg) translate3d(-5px, 0, 0); } }
  @media screen and (min-width: 992px) {
    .toggle .icon__line.end {
      transform: rotate(-90deg) translate3d(-3.5px, 0, 0); } }

/* ----- NAVBAR STYLE ----- */
.navMenu {
  position: fixed;
  background-color: #007d91;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -99; }
  .navMenu a {
    display: inline-block;
    letter-spacing: 5px;
    text-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
    position: relative;
    transform: translate3d(0, 100%, 0); }

.navMenu_active {
  transform: translate3d(0, 0, 0); }

.navMenu__list {
  text-align: center; }
  .navMenu__list li {
    margin-top: 35px;
    font-family: "Sanchez", serif;
    text-transform: uppercase;
    font-size: 3rem;
    position: relative;
    overflow: hidden; }
    @media screen and (min-width: 768px) {
      .navMenu__list li {
        font-size: 4rem; } }
    @media screen and (min-width: 992px) {
      .navMenu__list li {
        font-size: 5rem; } }
    .navMenu__list li:first-child {
      margin-top: 20px; }
    @media screen and (min-width: 768px) {
      .navMenu__list li:last-child {
        font-size: 3rem; } }
    .navMenu__list li a::after {
      content: "";
      width: 0px;
      height: 4px;
      position: absolute;
      background-color: #fff;
      top: 90%;
      left: 0;
      box-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
      transition: 0.4s; }
    .navMenu__list li a:hover::after {
      width: 100%; }

.responsive__proLinks {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (min-width: 992px) {
    .responsive__proLinks {
      display: none; } }

.responsive__langDisplay {
  display: flex;
  justify-content: space-evenly; }
  @media screen and (min-width: 768px) {
    .responsive__langDisplay {
      display: none; } }

.responsive__langBtn {
  border: 3px solid #fff;
  color: #fff;
  background-color: transparent;
  padding: 2px;
  border-radius: 3px;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 20px;
  width: 45px;
  height: 40px;
  transition: 0.3s;
  transform: translate3d(0, 100%, 0);
  box-shadow: 0 10px 30px rgba(2, 11, 22, 0.2);
  margin-right: 5px; }

@-webkit-keyframes navMenuLinksSlide {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes navMenuLinksSlide {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

/*COntents' font*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

.section {
  width: 100%;
  height: 100vh;
  position: relative; }

.fp-section.fp-table {
  display: flex !important; }

#fp-nav {
  z-index: 98 !important;
  top: auto !important;
  bottom: 10px !important;
  left: 50% !important;
  transform: translate3d(-50%, 0, 0) !important; }
  @media screen and (min-width: 992px) {
    #fp-nav {
      top: 50% !important;
      transform: translate3d(0, 0, 0) !important;
      left: 97% !important; } }
  #fp-nav ul li {
    display: inline-block !important; }
    @media screen and (min-width: 992px) {
      #fp-nav ul li {
        display: block !important; } }
    #fp-nav ul li a span {
      background-color: #fff !important; }

/* ----- SECTION 1 STYLE ----- */
.blue_text {
  color: #007d91; }

.title__s1 {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  overflow: hidden;
  margin-left: 8%;
  margin-right: 10px; }
  @media screen and (min-width: 768px) {
    .title__s1 {
      margin-left: 10%; } }
  .title__s1 h1 {
    font-family: "Sanchez", serif;
    font-size: 1rem;
    color: #fff;
    letter-spacing: 2px; }
    @media screen and (min-width: 768px) {
      .title__s1 h1 {
        font-size: 2.5rem; } }
    @media screen and (min-width: 992px) {
      .title__s1 h1 {
        font-size: 5.5rem; } }

.skills__s1 {
  position: absolute;
  top: 75%;
  transform: translate3d(0, -60%, 0);
  margin-left: 8%;
  font-size: 0.8rem;
  overflow: hidden;
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  color: #576066;
  letter-spacing: 3px; }
  @media screen and (min-width: 768px) {
    .skills__s1 {
      top: 72%;
      margin-left: 10%;
      font-size: 1rem; } }
  @media screen and (min-width: 992px) {
    .skills__s1 {
      top: 80%;
      transform: translate3d(0, -70%, 0);
      font-size: 1.2rem; } }

@-webkit-keyframes mainSlideIn {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes mainSlideIn {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes mainSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@keyframes mainSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

.s1.active .title__s1 h1 {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.3s forwards mainSlideIn;
          animation: 1s ease-out 0.3s forwards mainSlideIn; }

.s1.active .skills__s1 p {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.5s forwards mainSlideIn;
          animation: 1s ease-out 0.5s forwards mainSlideIn; }

.s1:not(.active) .title__s1 h1 {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards mainSlideOut;
          animation: 1s ease-in forwards mainSlideOut; }

.s1:not(.active) .skills__s1 p {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards mainSlideOut;
          animation: 1s ease-in forwards mainSlideOut; }

/* ----- SECTION 2 STYLE ----- */
.s2 {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.img__s2 {
  width: 100%;
  height: 80%;
  background-image: url(/static/media/pexels-danny-meneses-943096.12ab55f3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 75% 50%;
  position: relative;
  display: flex;
  flex-flow: row wrap; }
  .img__s2::after {
    content: "";
    background-color: rgba(0, 125, 145, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute; }
  .img__s2:hover .meta__container {
    opacity: 0.6; }

.meta__container {
  width: 100%;
  align-self: flex-end;
  z-index: 3;
  margin-bottom: 35px;
  transition: opacity 0.3s; }
  @media screen and (min-width: 768px) {
    .meta__container {
      margin-bottom: 55px; } }

.main__title {
  font-family: "Sanchez", serif;
  font-size: 2.5rem;
  color: #fff;
  margin: 0 10% 10px;
  transform: translate3d(-100%, 0, 0); }
  @media screen and (min-width: 768px) {
    .main__title {
      font-size: 5rem; } }

.description {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 10%;
  transform: translate3d(-100%, 0, 0);
  line-height: 30px; }
  @media screen and (min-width: 768px) {
    .description {
      font-size: 1.5rem;
      line-height: 35px; } }

.black__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  z-index: 20;
  transform: translate3d(0, 0, 0); }

.butn__container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .butn__container {
      margin-top: 55px; } }

.butn {
  padding: 14px 25px;
  border-radius: 5px;
  background-color: #007d91;
  font-family: "Roboto Slab", serif;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 400;
  transition: all 0.3s;
  display: inline-block;
  transform: translate3d(0, 100%, 0); }
  @media screen and (min-width: 768px) {
    .butn {
      font-size: 1.2rem; } }
  .butn:hover {
    background-color: rgba(0, 125, 145, 0.85);
    border-radius: 30px;
    color: #fff; }

@-webkit-keyframes curtainSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(100%, 0, 0); } }

@keyframes curtainSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes curtainSlideIn {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes curtainSlideIn {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes metaSlideIn {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes metaSlideIn {
  from {
    transform: translate3d(-100%, 0, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes metaSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@keyframes metaSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes btnSlideIn {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes btnSlideIn {
  from {
    transform: translate3d(0, 100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes btnSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(0, -100%, 0); } }

@keyframes btnSlideOut {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(0, -100%, 0); } }

.s2.active .black__curtain {
  -webkit-animation: 1s ease-in forwards curtainSlideOut;
          animation: 1s ease-in forwards curtainSlideOut; }

.s2.active .main__title {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.3s forwards metaSlideIn;
          animation: 1s ease-out 0.3s forwards metaSlideIn; }

.s2.active .description {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.4s forwards metaSlideIn;
          animation: 1s ease-out 0.4s forwards metaSlideIn; }

.s2.active .butn {
  transform: translate3d(0, 100%, 0);
  -webkit-animation: 0.5s ease-out 0.5s forwards btnSlideIn;
          animation: 0.5s ease-out 0.5s forwards btnSlideIn; }

.s2:not(.active) .black__curtain {
  -webkit-animation: 1s ease-in forwards curtainSlideIn;
          animation: 1s ease-in forwards curtainSlideIn; }

.s2:not(.active) .main__title {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards metaSlideOut;
          animation: 1s ease-in forwards metaSlideOut; }

.s2:not(.active) .description {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards metaSlideOut;
          animation: 1s ease-in forwards metaSlideOut; }

.s2:not(.active) .butn {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 0.5s ease-in forwards btnSlideOut;
          animation: 0.5s ease-in forwards btnSlideOut; }

/* ----- SECTION 3 STYLE ----- */
.s3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }

.img__s3 {
  width: 100%;
  height: 80%;
  background-image: url(/static/media/novi-raj-cjnpPXVNvrQ-unsplash.fff8cc04.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40% 50%;
  position: relative;
  display: flex;
  flex-flow: row wrap; }
  .img__s3::after {
    content: "";
    background-color: rgba(0, 125, 145, 0.5);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    border-radius: 3px; }
  .img__s3:hover .meta__container {
    opacity: 0.6; }

.s3.active .black__curtain {
  -webkit-animation: 1s ease-in forwards curtainSlideOut;
          animation: 1s ease-in forwards curtainSlideOut; }

.s3.active .main__title {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.3s forwards metaSlideIn;
          animation: 1s ease-out 0.3s forwards metaSlideIn; }

.s3.active .description {
  transform: translate3d(-100%, 0, 0);
  -webkit-animation: 1s ease-out 0.4s forwards metaSlideIn;
          animation: 1s ease-out 0.4s forwards metaSlideIn; }

.s3.active .butn {
  transform: translate3d(0, 100%, 0);
  -webkit-animation: 0.5s ease-out 0.5s forwards btnSlideIn;
          animation: 0.5s ease-out 0.5s forwards btnSlideIn; }

.s3:not(.active) .black__curtain {
  -webkit-animation: 1s ease-in forwards curtainSlideIn;
          animation: 1s ease-in forwards curtainSlideIn; }

.s3:not(.active) .main__title {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards metaSlideOut;
          animation: 1s ease-in forwards metaSlideOut; }

.s3:not(.active) .description {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 1s ease-in forwards metaSlideOut;
          animation: 1s ease-in forwards metaSlideOut; }

.s3:not(.active) .butn {
  transform: translate3d(0, 0, 0);
  -webkit-animation: 0.5s ease-in forwards btnSlideOut;
          animation: 0.5s ease-in forwards btnSlideOut; }

/*COntents' font*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

.main_row {
  padding-bottom: 25px; }
  @media screen and (min-width: 768px) {
    .main_row {
      padding-bottom: 75px; } }
  @media screen and (min-width: 992px) {
    .main_row {
      padding-bottom: 125px; } }

.intro {
  padding-top: 100px; }
  @media screen and (min-width: 768px) {
    .intro {
      padding-top: 150px; } }
  @media screen and (min-width: 992px) {
    .intro {
      padding-top: 200px; } }

h2 {
  text-transform: uppercase;
  color: #007d91;
  font-family: "Roboto Slab", serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 16px; }
  @media screen and (min-width: 992px) {
    h2 {
      font-size: 1.2rem; } }

h3 {
  text-transform: capitalize;
  color: #007d91;
  font-family: "Roboto Slab", serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 16px; }
  @media screen and (min-width: 992px) {
    h3 {
      font-size: 1rem;
      margin-top: 0; } }

.p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 20px; }
  @media screen and (min-width: 992px) {
    .p {
      font-size: 0.9rem; } }

.skills_set li {
  margin-left: 16px;
  margin-bottom: 16px; }
  .skills_set li i {
    font-size: 1rem;
    margin-right: 10px; }
  .skills_set li a {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 20px !important;
    font-size: 0.8rem;
    transition: all 0.3s; }
    @media screen and (min-width: 992px) {
      .skills_set li a {
        font-size: 0.9rem; } }
    .skills_set li a:hover {
      color: #007d91; }

.links {
  display: flex;
  flex-flow: row wrap; }

.website_link {
  font-family: "Sanchez", serif;
  font-size: 0.8rem;
  border-radius: 5px;
  color: #fff;
  padding: 8px 15px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s; }
  @media screen and (min-width: 768px) {
    .website_link {
      width: 164px; } }
  .website_link:hover {
    border-radius: 30px;
    color: #fff; }
  @media screen and (min-width: 768px) {
    .website_link:first-child {
      margin-right: 16px; } }
  .website_link i {
    font-size: 1.1rem;
    margin-right: 15px; }
    @media screen and (min-width: 992px) {
      .website_link i {
        font-size: 1.2rem; } }

.image_1 {
  background-image: url(/static/media/portfolio-works.c9d35614.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_1 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_1 {
      height: 480px; } }
  .image_1::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_1 h4 {
    font-family: "Sanchez", serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_1 h4 {
        font-size: 5rem; } }

.image_2 {
  background-image: url(/static/media/food-survey-works.16e0be15.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_2 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_2 {
      height: 480px; } }
  .image_2::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_2 h4 {
    font-family: "EB Garamond", serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_2 h4 {
        font-size: 5rem; } }

.image_3 {
  background-image: url(/static/media/potlatch-works.187d4c90.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_3 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_3 {
      height: 480px; } }
  .image_3::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_3 h4 {
    font-family: "Quicksand", sans-serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_3 h4 {
        font-size: 5rem; } }

.image_4 {
  background-image: url(/static/media/cuba-works.9c56983e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_4 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_4 {
      height: 480px; } }
  .image_4::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_4 h4 {
    font-family: "Pacifico", cursive;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_4 h4 {
        font-size: 5rem; } }

.marginBottom {
  margin-bottom: 16px; }

.html {
  color: #f16529; }

.css {
  color: #29a9df; }

.sass {
  color: #ce679a; }

.js {
  color: #f7df1e; }

.bootstrap {
  color: #7952b3; }

.library {
  color: #007d91; }

.wordpress {
  color: #135e96; }

.elementor {
  color: #db3157; }

.plug {
  color: #007d91; }

.react {
  color: #61dafb; }

.github {
  background-color: #2b3137; }
  .github:hover {
    background-color: rgba(43, 49, 55, 0.85); }

.live_link {
  background-color: #007d91; }
  .live_link:hover {
    background-color: rgba(0, 125, 145, 0.85); }

hr {
  border-top: 2.5px solid #007d91;
  border-radius: 25px; }
  @media screen and (min-width: 768px) {
    hr {
      border-top: 3px solid #007d91; } }
  @media screen and (min-width: 992px) {
    hr {
      border-top: 3.5px solid #007d91; } }

footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important; }
  footer span {
    font-family: "Sanchez", serif;
    color: #fff;
    font-size: 0.7rem; }
    @media screen and (min-width: 768px) {
      footer span {
        font-size: 0.8rem; } }
    @media screen and (min-width: 992px) {
      footer span {
        font-size: 0.9rem; } }

#top-button {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  padding: 0;
  background-color: #007d91;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.4rem;
  width: 30px;
  height: 30px;
  text-align: center;
  transition: 0.3s; }
  #top-button:hover {
    background-color: rgba(0, 125, 145, 0.85);
    border-radius: 20px; }
  @media screen and (min-width: 992px) {
    #top-button {
      bottom: 20px;
      right: 30px;
      width: 40px;
      height: 40px; } }
  #top-button i {
    padding: 3px; }
    @media screen and (min-width: 992px) {
      #top-button i {
        padding: 8px; } }

.fade {
  opacity: 1 !important; }

/*COntents' font*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

#main {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap; }

/* -----LEFT CONTAINER----- */
.left__container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 992px) {
    .left__container {
      width: 45%; } }

.infos__container {
  width: 100%;
  padding: 100px 50px 50px; }
  @media screen and (min-width: 768px) {
    .infos__container {
      padding: 150px 75px 50px; } }
  @media screen and (min-width: 992px) {
    .infos__container {
      padding: 200px 150px 50px; } }
  .infos__container h2 {
    text-transform: uppercase;
    color: #007d91;
    font-family: "Roboto Slab", serif;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 16px; }
    @media screen and (min-width: 992px) {
      .infos__container h2 {
        font-size: 1.2rem; } }
  .infos__container p {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 20px; }
    @media screen and (min-width: 992px) {
      .infos__container p {
        font-size: 0.9rem; } }
  .infos__container li {
    margin-bottom: 5px; }
  .infos__container a {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    transition: opacity 0.3s; }
    .infos__container a:hover {
      opacity: 0.6; }

.personalityContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 16px;
  margin-top: 16px;
  align-items: center;
  background-color: #007d91;
  border-radius: 5px;
  padding: 5px; }
  .personalityContainer img {
    width: 60px;
    height: auto; }
    @media screen and (min-width: 768px) {
      .personalityContainer img {
        width: 85px; } }
    @media screen and (min-width: 992px) {
      .personalityContainer img {
        width: 100px; } }

.pContainer {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px; }
  .pContainer p {
    color: #fff;
    font-family: "Sanchez", serif;
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 3px; }
    @media screen and (min-width: 768px) {
      .pContainer p {
        font-size: 0.9rem; } }
    @media screen and (min-width: 992px) {
      .pContainer p {
        font-size: 1rem; } }

.tooltip__container button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  transition: opacity 0.3s; }
  .tooltip__container button:hover {
    opacity: 0.6; }

.pp__container {
  width: 250px;
  height: 250px;
  border-radius: 5px;
  background-image: url(/static/media/20201219_105324-2.a07b2ec9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 100px;
  margin-left: 50px; }
  @media screen and (min-width: 768px) {
    .pp__container {
      width: 275px;
      height: 275px;
      margin-bottom: 75px;
      margin-left: 75px; } }
  @media screen and (min-width: 992px) {
    .pp__container {
      width: 300px;
      height: 300px;
      margin: 0 auto 100px; } }

/* -----RIGHT CONTAINER----- */
.right__container {
  width: 100%;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  position: relative; }
  @media screen and (min-width: 992px) {
    .right__container {
      width: 55%; } }

.resume__container {
  width: 100%;
  padding: 50px 50px 100px; }
  @media screen and (min-width: 768px) {
    .resume__container {
      padding: 50px 75px 75px; } }
  @media screen and (min-width: 992px) {
    .resume__container {
      padding: 200px 100px 200px 150px; } }
  .resume__container h2 {
    text-transform: uppercase;
    color: #007d91;
    font-family: "Roboto Slab", serif;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 2px; }
    @media screen and (min-width: 992px) {
      .resume__container h2 {
        font-size: 1.2rem; } }
  .resume__container h3 {
    text-transform: uppercase;
    color: #fff;
    font-family: "Roboto Slab", serif;
    font-size: 0.9rem;
    letter-spacing: 2px;
    margin-bottom: 16px;
    margin-top: 16px; }
    @media screen and (min-width: 992px) {
      .resume__container h3 {
        font-size: 1rem; } }
  .resume__container h4 {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 1px; }
    @media screen and (min-width: 992px) {
      .resume__container h4 {
        font-size: 0.9rem; } }
  .resume__container p {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 300;
    margin-bottom: 0; }
    @media screen and (min-width: 992px) {
      .resume__container p {
        font-size: 0.9rem; } }

.firstP {
  color: #fff;
  padding-left: 15px; }

.secondP {
  color: #007d91;
  font-style: italic; }

.marginTop {
  margin-top: 16px; }

.margin45 {
  margin-left: 45px; }

.marginBottom {
  margin-bottom: 16px !important; }

.dates {
  color: #576066; }

/* -----DOWNLOAD RESUME----- */
.btn__container {
  width: 100%; }
  .btn__container a {
    font-family: "Sanchez", serif;
    font-size: 0.8rem;
    border-radius: 5px;
    color: #fff;
    background-color: #007d91;
    padding: 8px 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s; }
    @media screen and (min-width: 768px) {
      .btn__container a {
        font-size: 1rem;
        width: 204px; } }
    .btn__container a:hover {
      background-color: rgba(0, 125, 145, 0.85);
      border-radius: 30px; }
  .btn__container i {
    font-size: 1.1rem;
    margin-right: 15px; }
    @media screen and (min-width: 992px) {
      .btn__container i {
        font-size: 1.2rem; } }

footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important; }
  footer span {
    font-family: "Sanchez", serif;
    color: #fff;
    font-size: 0.7rem; }
    @media screen and (min-width: 768px) {
      footer span {
        font-size: 0.8rem; } }
    @media screen and (min-width: 992px) {
      footer span {
        font-size: 0.9rem; } }

#top-button {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  padding: 0;
  background-color: #007d91;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.4rem;
  width: 30px;
  height: 30px;
  text-align: center;
  transition: 0.3s; }
  #top-button:hover {
    background-color: rgba(0, 125, 145, 0.85);
    border-radius: 20px; }
  @media screen and (min-width: 992px) {
    #top-button {
      bottom: 20px;
      right: 30px;
      width: 40px;
      height: 40px; } }
  #top-button i {
    padding: 3px; }
    @media screen and (min-width: 992px) {
      #top-button i {
        padding: 8px; } }

.fade {
  opacity: 1 !important; }

