@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700;800;900&display=swap");
/*COntents' font*/
@import url("https://fonts.googleapis.com/css2?family=Sanchez&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box; }

::selection {
  text-shadow: none;
  background-color: rgba(0, 125, 145, 0.5);
  color: #fff; }

html {
  line-height: normal !important; }

body {
  height: 100%;
  background-color: #141414;
  scroll-behavior: smooth;
  line-height: normal !important; }

ul {
  list-style: none; }

a {
  text-decoration: none !important;
  line-height: normal !important; }

.main_row {
  padding-bottom: 25px; }
  @media screen and (min-width: 768px) {
    .main_row {
      padding-bottom: 75px; } }
  @media screen and (min-width: 992px) {
    .main_row {
      padding-bottom: 125px; } }

.intro {
  padding-top: 100px; }
  @media screen and (min-width: 768px) {
    .intro {
      padding-top: 150px; } }
  @media screen and (min-width: 992px) {
    .intro {
      padding-top: 200px; } }

h2 {
  text-transform: uppercase;
  color: #007d91;
  font-family: "Roboto Slab", serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 16px; }
  @media screen and (min-width: 992px) {
    h2 {
      font-size: 1.2rem; } }

h3 {
  text-transform: capitalize;
  color: #007d91;
  font-family: "Roboto Slab", serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 16px; }
  @media screen and (min-width: 992px) {
    h3 {
      font-size: 1rem;
      margin-top: 0; } }

.p {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 20px; }
  @media screen and (min-width: 992px) {
    .p {
      font-size: 0.9rem; } }

.skills_set li {
  margin-left: 16px;
  margin-bottom: 16px; }
  .skills_set li i {
    font-size: 1rem;
    margin-right: 10px; }
  .skills_set li a {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 20px !important;
    font-size: 0.8rem;
    transition: all 0.3s; }
    @media screen and (min-width: 992px) {
      .skills_set li a {
        font-size: 0.9rem; } }
    .skills_set li a:hover {
      color: #007d91; }

.links {
  display: flex;
  flex-flow: row wrap; }

.website_link {
  font-family: "Sanchez", serif;
  font-size: 0.8rem;
  border-radius: 5px;
  color: #fff;
  padding: 8px 15px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s; }
  @media screen and (min-width: 768px) {
    .website_link {
      width: 164px; } }
  .website_link:hover {
    border-radius: 30px;
    color: #fff; }
  @media screen and (min-width: 768px) {
    .website_link:first-child {
      margin-right: 16px; } }
  .website_link i {
    font-size: 1.1rem;
    margin-right: 15px; }
    @media screen and (min-width: 992px) {
      .website_link i {
        font-size: 1.2rem; } }

.image_1 {
  background-image: url("../assets/img/portfolio-works.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_1 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_1 {
      height: 480px; } }
  .image_1::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_1 h4 {
    font-family: "Sanchez", serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_1 h4 {
        font-size: 5rem; } }

.image_2 {
  background-image: url("../assets/img/food-survey-works.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_2 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_2 {
      height: 480px; } }
  .image_2::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_2 h4 {
    font-family: "EB Garamond", serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_2 h4 {
        font-size: 5rem; } }

.image_3 {
  background-image: url("../assets/img/potlatch-works.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_3 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_3 {
      height: 480px; } }
  .image_3::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_3 h4 {
    font-family: "Quicksand", sans-serif;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_3 h4 {
        font-size: 5rem; } }

.image_4 {
  background-image: url("../assets/img/cuba-works.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 768px) {
    .image_4 {
      height: 408px; } }
  @media screen and (min-width: 992px) {
    .image_4 {
      height: 480px; } }
  .image_4::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 2; }
  .image_4 h4 {
    font-family: "Pacifico", cursive;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #fff;
    z-index: 3; }
    @media screen and (min-width: 768px) {
      .image_4 h4 {
        font-size: 5rem; } }

.marginBottom {
  margin-bottom: 16px; }

.html {
  color: #f16529; }

.css {
  color: #29a9df; }

.sass {
  color: #ce679a; }

.js {
  color: #f7df1e; }

.bootstrap {
  color: #7952b3; }

.library {
  color: #007d91; }

.wordpress {
  color: #135e96; }

.elementor {
  color: #db3157; }

.plug {
  color: #007d91; }

.react {
  color: #61dafb; }

.github {
  background-color: #2b3137; }
  .github:hover {
    background-color: rgba(43, 49, 55, 0.85); }

.live_link {
  background-color: #007d91; }
  .live_link:hover {
    background-color: rgba(0, 125, 145, 0.85); }

hr {
  border-top: 2.5px solid #007d91;
  border-radius: 25px; }
  @media screen and (min-width: 768px) {
    hr {
      border-top: 3px solid #007d91; } }
  @media screen and (min-width: 992px) {
    hr {
      border-top: 3.5px solid #007d91; } }

footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3) !important; }
  footer span {
    font-family: "Sanchez", serif;
    color: #fff;
    font-size: 0.7rem; }
    @media screen and (min-width: 768px) {
      footer span {
        font-size: 0.8rem; } }
    @media screen and (min-width: 992px) {
      footer span {
        font-size: 0.9rem; } }

#top-button {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  padding: 0;
  background-color: #007d91;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.4rem;
  width: 30px;
  height: 30px;
  text-align: center;
  transition: 0.3s; }
  #top-button:hover {
    background-color: rgba(0, 125, 145, 0.85);
    border-radius: 20px; }
  @media screen and (min-width: 992px) {
    #top-button {
      bottom: 20px;
      right: 30px;
      width: 40px;
      height: 40px; } }
  #top-button i {
    padding: 3px; }
    @media screen and (min-width: 992px) {
      #top-button i {
        padding: 8px; } }

.fade {
  opacity: 1 !important; }
